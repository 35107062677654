.contact-header {
    padding: 60px 20px;
    background-color: #35495e;
    background-size: cover;
    background-position: center;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    margin-bottom: 40px;
  }
  
  .contact-form-section {
    background-color: #ffffff;
    padding: 40px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form-section h2 {
    color: #35495e;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .contact-card {
    border: none;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-card .card-title {
    color: #35495e;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .social-media-section {
    padding: 40px 20px;
  }
  
  .social-media-section h2 {
    color: #35495e;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .social-icon {
    font-size: 2rem;
    color: #35495e;
    margin: 0 15px;
    transition: color 0.3s;
  }
  
  .social-icon:hover {
    color: #ff6f61;
  }
  