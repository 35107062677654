html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
  color: #333;
}

body {
  color: #333;
  line-height: 1.6;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  flex: 1;
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
}

footer {
  background-color: #35495e;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  font-size: 0.9rem;
}

.navbar {
  background-color: #35495e;
}

.navbar .navbar-brand {
  font-weight: bold;
  color: #fff;
}

.navbar .nav-link {
  color: #fff;
  font-size: 1.1rem;
  transition: color 0.3s;
}

.navbar .nav-link:hover {
  color: #ff6f61;
}

.btn-primary {
  background-color: #ff6f61;
  border-color: #ff6f61;
}

.btn-primary:hover {
  background-color: #e55a50;
}
.carousel-item {
  background-color: #35495e;
}
.carousel-item img {
  opacity: 0.9;
  height: 450px;
  object-fit: cover;
}

.carousel-caption h1, .carousel-caption h3 {
  font-family: 'Lato', sans-serif;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.carousel-caption p {
  color: #f5f5f5;
}

.highlight-section .card {
  background-color: #ffffff;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  border-radius: 8px;
}

.highlight-section .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.highlight-section .card-title {
  font-size: 1.6rem;
  font-weight: 700;
  color: #35495e;
  margin-bottom: 15px;
}

.highlight-section .card-text {
  font-size: 1rem;
  color: #777;
  margin-bottom: 20px;
}

.highlight-section .btn {
  background-color: #ff6f61;
  border-color: #ff6f61;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 1rem;
}

.highlight-section .btn:hover {
  background-color: #e55a50;
}

.highlight-section {
  margin-top: 40px;
}

/* Ensure there's no conflict with Bootstrap's responsive styles */
.navbar {
  margin-bottom: 20px;
}

/* Ensure the footer sticks to the bottom of the page if needed */
html, body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  margin-top: auto;
}

/* Custom white color for the hamburger menu icon */
.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}


/* Dropdown Menu Styling */
.navbar .dropdown-menu {
  background-color: #ffffff; /* Light background for contrast */
  border: 1px solid #ccc; /* Add a subtle border */
  border-radius: 4px; /* Slightly rounded corners */
  padding: 10px 0; /* Add padding for better spacing */
}

/* Dropdown Item Styling */
.navbar .dropdown-item {
  color: #35495e; /* Dark text color for readability */
  font-size: 1rem; /* Slightly smaller font size */
  padding: 10px 20px; /* Increase padding for better spacing */
  transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
}

.navbar .dropdown-item:hover, 
.navbar .dropdown-item:focus {
  background-color: #ff6f61; /* Use the brand color for hover effect */
  color: #ffffff; /* White text on hover */
}

/* Dropdown Toggle Arrow Styling */
.navbar .dropdown-toggle::after {
  border-top-color: #ffffff; /* White arrow to match navbar text */
}

.navbar .dropdown-toggle {
  color: #ffffff; /* White text for the toggle */
  font-size: 1.1rem; /* Match the size of other navbar links */
}

/* Optional: Add a subtle shadow to the dropdown menu */
.navbar .dropdown-menu {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
