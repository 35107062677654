.get-involved-header {
    padding: 60px 20px;
    background-color: #35495e;
    background-size: cover;
    background-position: center;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    margin-bottom: 40px;
  }
  
  .ways-to-involve {
    padding: 40px 20px;
  }
  
  .ways-to-involve h2 {
    color: #35495e;
    font-weight: 700;
    margin-bottom: 40px;
  }
  
  .involve-card {
    border: none;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .involve-card .card-title {
    color: #35495e;
    font-weight: 600;
    margin-top: 15px;
  }
  
  .involve-card .card-text {
    color: #555;
    font-size: 1rem;
  }
  
  .join-us-section {
    background-color: #e3f2fd;
    padding: 40px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .join-us-section h2 {
    color: #35495e;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .join-us-section p {
    color: #555;
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  