.volunteering-header {
    margin-bottom: 40px;
    padding: 20px 0;
  }
  
  .volunteering-header h1 {
    font-size: 2.5rem;
    color: #35495e;
  }
  
  .volunteering-header p {
    color: #777;
    font-size: 1.2rem;
  }
  
  .why-volunteer-section h2 {
    font-size: 1.8rem;
    color: #35495e;
    margin-bottom: 20px;
  }
  
  .why-volunteer-section p,
  .why-volunteer-section ul {
    font-size: 1rem;
    color: #555;
  }
  
  .why-volunteer-section ul {
    padding-left: 20px;
  }
  
  .volunteering-opportunities-section {
    margin-top: 50px;
  }
  
  .volunteering-opportunities-section h2 {
    font-size: 2rem;
    color: #35495e;
    margin-bottom: 40px;
  }
  
  .volunteering-card {
    background-color: #ffffff;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .volunteering-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .volunteering-card img {
    height: 200px;
    object-fit: cover;
  }
  
  .volunteering-card .card-title {
    font-size: 1.5rem;
    color: #35495e;
  }
  
  .volunteering-card .card-text {
    font-size: 1rem;
    color: #777;
  }
  
  .volunteering-card .btn-primary {
    background-color: #ff6f61;
    border-color: #ff6f61;
  }
  
  .volunteering-card .btn-primary:hover {
    background-color: #e55a50;
  }
  