.membership-header {
    margin-bottom: 40px;
    padding: 20px 0;
  }
  
  .membership-header h1 {
    font-size: 2.5rem;
    color: #35495e;
  }
  
  .membership-header p {
    color: #777;
    font-size: 1.2rem;
  }
  
  .membership-benefits-section h2 {
    font-size: 2rem;
    color: #35495e;
    margin-bottom: 30px;
  }
  
  .membership-benefits-section h3 {
    font-size: 1.5rem;
    color: #35495e;
    margin-bottom: 15px;
  }
  
  .membership-benefits-section p {
    font-size: 1rem;
    color: #555;
  }
  
  .membership-tiers-section {
    margin-top: 50px;
  }
  
  .membership-tiers-section h2 {
    font-size: 2rem;
    color: #35495e;
    margin-bottom: 40px;
  }
  
  .membership-card {
    background-color: #ffffff;
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .membership-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .membership-card img {
    height: 200px;
    object-fit: cover;
  }
  
  .membership-card .card-title {
    font-size: 1.5rem;
    color: #35495e;
  }
  
  .membership-card .card-text {
    font-size: 1rem;
    color: #777;
  }
  
  .membership-card .btn-primary {
    background-color: #ff6f61;
    border-color: #ff6f61;
  }
  
  .membership-card .btn-primary:hover {
    background-color: #e55a50;
  }
  