.about-header {
    padding: 60px 20px;
    background-color: #35495e;
    background-size: cover;
    background-position: center;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    margin-bottom: 40px;
  }
  
  .mission-section, .history-section {
    background-color: #ffffff;
    padding: 40px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .mission-section h2, .history-section h2 {
    color: #35495e;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .mission-section p, .history-section p {
    color: #555;
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .team-section {
    padding: 40px 20px;
  }
  
  .team-section h2 {
    color: #35495e;
    font-weight: 700;
    margin-bottom: 40px;
  }
  
  .team-card {
    border: none;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }
  
  .team-card .card-title {
    color: #35495e;
    font-weight: 600;
    margin-top: 15px;
  }
  
  .team-card .card-text {
    color: #777;
    font-size: 1rem;
  }
  