/* Styling for the carousel text and overlay */
.carousel-caption {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  padding: 20px;
  border-radius: 8px;
  color: #fff;
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.8); /* Strong shadow for contrast */
  left: 50%;
  transform: translateX(-50%);
  text-align: center; /* Ensure text is centered */
  bottom: 20%; /* Move the caption higher */
}

.carousel-item {
  position: relative;
}

.carousel-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Dark overlay on images */
  z-index: 1;
}

.carousel-caption {
  position: absolute;
  z-index: 2; /* Ensures text stays on top of overlay */
  width: 80%; /* Adjust width for better text layout */
  left: 50%;
  transform: translateX(-50%);
}

.carousel-caption h1, .carousel-caption h3 {
  font-size: 2.5rem;
  font-weight: 700; /* Bold titles */
}

.carousel-caption p {
  font-size: 1.2rem;
  font-weight: 500; /* Thicker paragraph text */
}

/* Card styling in highlight section */
.highlight-section .card {
  background-color: #ffffff; /* White background for cards */
  border: 1px solid #e0e0e0; /* Light border */
  box-shadow: none; /* Removing heavy shadow */
  transition: transform 0.3s ease-in-out;
}

.highlight-section .card:hover {
  transform: translateY(-3px); /* Slight hover effect */
}

.highlight-section .card-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: #4a90e2; /* Softer blue for titles */
}

.highlight-section .card-text {
  font-size: 1rem;
  color: #555;
}

.highlight-section .btn {
  background-color: #ff6f61;
  border-color: #ff6f61;
}

.highlight-section .btn:hover {
  background-color: #e65a50; /* Slightly darker color */
}
